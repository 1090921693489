<template>
  <q-form ref="editForm">
    <c-card title="상세" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-show="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="map"
            :mappingType="mappingType"
            label="일괄저장" 
            icon="save"
            @beforeAction="save"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> -->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                :editable="editable"
                :required="true"
                label="지도명"
                name="mapName"
                v-model="map.mapName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant
                :required="true"
                :editable="editable"
                type="edit" 
                name="plantCd" 
                v-model="map.plantCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-radio 
                :editable="editable"
                :comboItems="useFlagItems"
                label="사용여부"
                name="useFlag" 
                v-model="map.useFlag">
              </c-radio>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        </div>
      </template>
    </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-upload 
          style="margin-top:0 !important"
          :attachInfo="attachInfo"
          :editable="editable"
          @files="setMap">
        </c-upload>
        <c-table
          ref="table"
          title="지도별 공정 목록"
          tableId="mapProcess"
          :editable="editable"
          :columns="grid.columns"
          :data="map.mapProcesses"
          :gridHeight="grid.height"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :isFullScreen="false"
          :isExcelDown="false"
          selection="multiple"
          rowKey="processCd"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" 
                icon="add" 
                @btnClicked="addProcess" />
              <c-btn v-if="editable&&map.mapProcesses&&map.mapProcesses.length > 0" 
                icon="remove" 
                @btnClicked="removeProcess" />
            </q-btn-group>
          </template>
          <!-- <template slot="suffixTitle">
            <font class="text-negative" style="font-size:0.8em;font-weight:300;">
              <div>
              1. 비고란에는 자체점검 시 조치완료된 사항 또는 재점검이 필요한 사항을 적습니다.
              </div>
              <div>
              2. 유해화학물질 취급시설 자체점검을 하려는 자는 양식의 점검 항목이 모두 포함된 별도의 서식을 사용할 수 있으며, 점검 항목이 모두 포함되어 있음을 명확하게 알 수 있도록 표기해야 합니다.)
              </div>
            </font>
          </template> -->
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <c-card title="지도" class="cardClassDetailForm" :noMarginPadding="true" :height="imgHeight"> 
          <template slot="card-detail">
            <div class="col-12">
              <div id="parent" ref="parent">
                <!-- :style="{height: imgHeight}"> -->
                <div v-if="map.mapProcesses&&map.mapProcesses.length>0&&setComplete"
                  :style="{'height': imgHeight}">
                  <VueDraggableResizable
                    v-for="(mp, idx) in map.mapProcesses"
                    :key="idx"
                    ref="markImage"
                    class="my-class"
                    :parent="true"
                    :draggable="editable"
                    :resizable="editable"
                    class-name-dragging="my-dragging-class"
                    :x="mp.x"
                    :y="mp.y"
                    :w="mp.w" :h="mp.h"
                    :grid="[10, 10]"
                    :minHeight="40" :minWidth="40"
                    @dragging="(x, y) => onDrag(mp, x, y)"
                    @resizing="(x, y, w, h) => onResize(mp, x, y, w, h)"
                  >
                  <b>{{mp.processName}}</b>
                  </VueDraggableResizable>
                </div>
              </div>
              <!-- <q-img :src="mapSrc" fit="scale-down"
                :contain="true"
                :ratio="1">
                <template v-slot:loading>
                  <q-spinner-gears color="white" />
                </template>
              </q-img> -->
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-map-detail',
  components: { VueDraggableResizable },
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          sopMapId: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      map: {
        sopMapId: '',  // 지도 일련번호
        plantCd: '',  // 사업장 코드
        mapName: '',  // 지도명
        ratio: null,
        useFlag: '',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        sysAttachFileId: '',  // 수정자 ID
        contentType: '',  // 수정자 ID
        mapProcesses: [], // 공정s
        deleteMapProcesses: [], // 공정s
      },
      grid: {
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
        ],
        height: '600px'
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'WORK_MAP',
        taskKey: '',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      setComplete: false,
      mapSrc: require('@/assets/images/no-image.png'),
      imgHeight: '',
      editable: true,
      detailUrl: '',
      saveUrl: '',
      isSave: false,
      mappingType: 'POST',
      popupOptions: {
        isFull: false,
        width: '60%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 390);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
      this.imgHeight = String(this.contentHeight - 200) + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.swp.map.get.url
      this.saveUrl = transactionConfig.sop.swp.map.save.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.sopMapId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopMapId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.map, _result.data);
          this.$set(this.attachInfo, 'taskKey', this.map.sopMapId)
        },);
      }
    },
    setMap(files) {
      if (files && files.length > 0) {
        let data = files[0];
        this.$comm.previewImage({
          sysAttachFileId: data.sysAttachFileId,
          contentType: data.contentType,
        }).then(_result => {
          this.mapSrc = _result;
          this.setCanvas(10)
        });
      } else {
        this.mapSrc = '';
        this.setCanvas(10)
      }
    },
    setCanvas(size) {
      let image = new Image();
      image.src = this.mapSrc;
      let thisVue = this;
      image.onload = function () {
        let parent = document.getElementById('parent');
        thisVue.map.ratio = image.width / image.height
        let _width = thisVue.map.ratio * 575

        parent.style.cssText  = 'background: linear-gradient(-90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / ' 
          + size + 'px ' + size + 'px, linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / ' + size + 'px ' + size + 'px '
          + ', url("' + thisVue.mapSrc + '") no-repeat left/contain;'
          + 'overflow:auto; transform-origin: left top;'
          // + 'background-size: 800px;'
          + 'height:575px;width:' + _width + ';';
          // + 'height:100%;width:800px;';
          // + 'height:800px;width:800px';

        setTimeout(() => {
          thisVue.setComplete = true;
        }, 300);
      };
    },
    /* eslint-disable no-unused-vars */
    addProcess() {
      if (!this.mapSrc) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '지도 이미지를 업로드 후 공정 추가바랍니다.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.popupOptions.title = '단위공정 검색';
      this.popupOptions.param = {
        type: 'multiple',
        processLevelCd: '20',
        plantCd: this.map.plantcd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/processPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeProcessPopup;
    },
    closeProcessPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.map.mapProcesses) this.map.mapProcesses = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.map.mapProcesses, { processCd: item.processCd }) === -1) {
            this.map.mapProcesses.push({ 
              sopMapProcessId: uid(),  // 지도별 공정 일련번호
              sopMapId: this.popupParam.sopMapId,  // 지도 일련번호
              processCd: item.processCd,  // 공정코드
              processName: item.processName,  // 공정명
              mapName: this.map.mapName,  // 지도명
              x: 0,
              y: 0,
              w: 100,
              h: 100,
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,  // 등록일
            });
          }
        })
      }
    },
    removeProcess() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.map.deleteMapProcesses, { processCd: item.processCd }) === -1
            && item.editFlag !== 'C') {
            this.map.deleteMapProcesses.push(item)
          }
          this.map.mapProcesses = this.$_.reject(this.map.mapProcesses, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    onDrag(item, x, y) {
      this.$set(item, 'x', x)
      this.$set(item, 'y', y)
      if (item.editFlag !== 'C') {
        this.$set(item, 'editFlag', 'U')
        this.$set(item, 'chgUserId', this.$store.getters.user.userId)
      }
    },
    onResize(item, x, y, w, h) {
      this.$set(item, 'x', x)
      this.$set(item, 'y', y)
      this.$set(item, 'w', w)
      this.$set(item, 'h', h)
      if (item.editFlag !== 'C') {
        this.$set(item, 'editFlag', 'U')
        this.$set(item, 'chgUserId', this.$store.getters.user.userId)
      }
    },
    save() {
      if (this.popupParam.sopMapId) {
        this.mappingType = 'PUT'
      } else {
        this.mappingType = 'POST'
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.map.regUserId = this.$store.getters.user.userId
              this.map.chgUserId = this.$store.getters.user.userId

              if (this.map.mapProcesses && this.map.mapProcesses.length > 0) {
                this.$_.forEach(this.map.mapProcesses, item => {
                  this.$set(item, 'mapName', this.map.mapName)
                })
              }
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.sopMapId = result.data
      this.$set(this.attachInfo, 'taskKey', result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>
<style>
.my-class {
  /* background-color: #9E9E9E; */
  border: 3px dashed #FF5722;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  position: absolute;
  text-align: center;
}

.my-dragging-class {
  background-color: #FF5722;
  border: 3px dashed #FF5722;
}
</style>